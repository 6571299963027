<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="deleted" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import deletedSchemas from './deleted-history'
  import dtHelper from 'src/helpers/datatable'

  export default{
    components: {
      MyTable,
      MyFilter
    },
    computed: {
      deleted() {
        let rows = this.$store.state.deleted;
        return dtHelper.filterByRules(rows, this.filterOutput);
      }
    },
    data () {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(deletedSchemas),
        actions: [],
        filter: {},
        rules: dtHelper.buildRules(deletedSchemas)
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchDeleted');

      this.$store.dispatch('setPageTitle', 'dữ liệu đã xóa');
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
  }
</style>
